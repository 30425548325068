<template>
  <div>
    <b-card>
      <div class="app_title_box">
        <h4>Suspended Businesses</h4>
        <!-- <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="showModal"
        >
          New
        </b-button> -->
      </div>
      <div class="mb-2">
        <b-row>
          <b-col cols="3">
            <b-form-group
              label="Search suspended businesses"
              label-for="v-search-keyword"
            >
              <b-form-input
                id="h-search-keyword"
                v-model="filter"
                type="text"
                placeholder="search here..."
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <b-table
        :items="suspended_businesses"
        :fields="fields"
        striped
        sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :filter="filter"
        :filter-included-fields="filterOn"
        responsive
        @row-clicked="showDetails"
      >

        <!-- <template #cell(cover)="data">
          <b-avatar :src="getImage(data.value)" />
        </template> -->
        <template #cell(id)="data">
          {{ data.value }}
        </template>
        <template #cell(state_id)="data">
          {{ states.filter(item => item.id === data.value)[0].name }}
        </template>
        <template #cell(county_id)="data">
          {{ allCounties.filter(item => item.id === data.value)[0].name }}
        </template>
        <template #cell(category_id)="data">
          {{ categories.filter(item => item.id === data.value)[0].name }}
        </template>
        <template #cell(system_status)="data">
          <b-badge
            :variant="data.value === 'active' ? 'light-success':
              data.value === 'cancel' ? 'light-danger' : data.value === 'waiting for review' ? 'light-warning' : 'light-secondary'
            "
          >
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(application_status)="data">
          <b-badge
            :variant="data.value === 'active' ? 'light-success':
              data.value === 'cancel' ? 'light-danger' : data.value === 'waiting for review' ? 'light-warning' : 'light-secondary'
            "
          >
            {{ data.value }}
          </b-badge>
        </template>
        <!-- <template #cell(status)="data">
          <b-form-checkbox
            :checked="data.value === true ? 'true' : 'false'"
            class="custom-control-success"
            name="check-button"
            switch
            @change="toggleBusinessProfile($event, data.item.id)"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </template> -->
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="per_page"
        size="lg"
        class="mt-3 pagination-success"
        align="center"
        @change="onPageChange"
      />
    </b-card>

    <BusinessProfileDetails />
  </div>
</template>

<script>
import {
  BCard, BButton, BModal, VBModal, BCardText, BTable, BAvatar, BPagination,
  BFormInput,BBadge,
  BRow, BCol, BFormGroup, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BusinessProfileDetails from './components/BusinessProfileDetails.vue'

export default {
  components: {
    BCard,
    BButton,
    BModal,
    BCardText,
    BTable,
    BAvatar,
    BPagination,
    BFormInput,
    BRow,
    BBadge,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BusinessProfileDetails,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      is_update: false,
      fields: [{ key: 'id', label: '#ID' }, { key: 'name', label: 'Business Name', sortable: true }, { key: 'state_id', label: 'State', sortable: true }, { key: 'county_id', label: 'County', sortable: true }, { key: 'category_id', label: 'Category', sortable: true }, { key: 'system_status', label: 'System Status', sortable: true }, { key: 'application_status', label: 'Application Status', sortable: true }],
      currentPage: 1,
      per_page: 10,
      sortBy: 'id',
      sortDesc: false,
      searchTerm: '',
      filter: null,
      filterOn: [],
    }
  },
  computed: {
    suspended_businesses() {
      const data = this.$store.state.businessProfile.suspended_businesses
      const items = data.map(item => item.business_profile)
      return items
    },
    rows() {
      return this.suspended_businesses.length
    },
    states() {
      return this.$store.state.state.states
    },
    counties() {
      return this.$store.state.county.counties
    },
    allCounties() {
      return this.$store.state.county.all_counties
    },
    categories() {
      return this.$store.state.category.categories
    },
  },
  watch: {
    '$store.state.businessProfile.admin_business_profiles': {
      handler() {
        const dom = this
        setTimeout(() => {
          dom.getSuspendedBusiness()
        }, 200)
      },
    },
  },
  mounted() {
    this.getSuspendedBusiness()
  },
  methods: {
    getSuspendedBusiness() {
      return this.$store.dispatch('businessProfile/getSuspendedBusiness')
    },
    onPageChange(page) {
      this.$store.dispatch('statistic/getVendorStatistics', { page, per_page: this.per_page })
    },
    toggleBusinessProfile(status, id) {
      const data = { business_profile_id: id, status }
      this.$store.dispatch('businessProfile/toggleBusinessProfile', data)
      setTimeout(() => {
        this.getSuspendedBusiness()
      }, 100)
    },
    getImage(image) {
      return process.env.VUE_APP_STORAGE_PATH + image
    },
    showModal() {
      this.$bvModal.show('app-modal')
    },
    hide() {
      this.$bvModal.hide('app-modal')
    },
    showDetails(item) {
      this.$store.dispatch('businessProfile/getSingleProfileDetails', item.id)
    },
  },
}
</script>

<style>

</style>
